<template>
  <ContentWrapper id="coupon-detail">
    <div class="card card-default">
      <div class="card-body">
        <div class="card card-default" v-if="coupon_info">
          <div class="card-body">
            <el-row :gutter="20" class="m-4 text-center">
              <el-col :span="6">
                <div class="text-uppercase">總數量</div>
                <div class="h1 m-0 text-bold">{{ coupon_info.total }}</div>
              </el-col>
              <!--<el-col :span="4">-->
                <!--<div class="text-uppercase">已領取數量</div>-->
                <!--<div class="h1 m-0 text-bold">{{ coupon_info.get_num }}</div>-->
              <!--</el-col>-->
              <el-col :span="6">
                <div class="text-uppercase">已使用數量</div>
                <div class="h1 m-0 text-bold">{{ coupon_info.used_num }}</div>
              </el-col>
              <!--<el-col :span="4">-->
                <!--<div class="text-uppercase">已領取人數</div>-->
                <!--<div class="h1 m-0 text-bold">{{ coupon_info.get_user_num }}</div>-->
              <!--</el-col>-->
              <el-col :span="6">
                <div class="text-uppercase">已使用人數</div>
                <div class="h1 m-0 text-bold">{{ coupon_info.used_user_num }}</div>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="card card-default" v-if="coupon_info">
          <div class="card-body">
            <el-row :gutter="20" class="m-4">
              <el-col :span="6">優惠券名稱:{{ coupon_info.name }}</el-col>
              <el-col :span="6">優惠券狀態:{{ coupon_info.status_str }}</el-col>
              <el-col :span="6">限首單使用:{{ coupon_info.new_user_only ? '是' : '否' }}</el-col>
              <!--<el-col :span="4">公開領取:{{ coupon_info.public ? '是' : '否' }}</el-col>-->
            </el-row>
            <el-row :gutter="20" class="m-4">
              <el-col :span="6">發放時間:{{ coupon_info.created_at }}</el-col>
              <el-col :span="6">開始時間:{{ coupon_info.start_date }}</el-col>
              <el-col :span="6">結束時間:{{ coupon_info.end_date }}</el-col>
            </el-row>
          </div>
        </div>

        <div class="card card-default">
          <div class="card-body">
            <v-client-table id="coupon-table" :data="coupons" :columns="tableConfigs.columns"
                            :options="tableConfigs.options">

              <template slot="order_id" slot-scope="props">
                <router-link
                    :to="{ name: 'order-detail', params: { id: props.row.order_id }}"
                >
                  {{ props.row.order_id }}
                </router-link>
              </template>
              <!--<template slot="detail" slot-scope="props">-->
                <!--<router-link  :to="{ name: 'order-detail', params: { id: props.row.order_id } }" >-->
                <!--{{ props.row.order_id }}-->
                <!--</router-link>-->
                <!--<el-link :underline="false" @click="showOrder(props.row.orders)">查看</el-link>-->
                <!--<el-link :underline="false" v-else>-</el-link>-->
              <!--</template>-->

            </v-client-table>
          </div>
        </div>

        <!--<el-dialog-->
            <!--title=""-->
            <!--:visible.sync="ordersDialogVisible"-->
        <!--&gt;-->
          <!--<table class="table table-striped">-->
            <!--<thead>-->
            <!--<tr>-->
              <!--<th>訂單編號</th>-->
              <!--<th>訂單金額</th>-->
              <!--<th>優惠金額</th>-->
              <!--<th>實付金額</th>-->
            <!--</tr>-->
            <!--</thead>-->
            <!--<tbody>-->
            <!--<tr v-for="order in currentOrders" :key="order.id">-->
              <!--<td>-->
                <!--<router-link  :to="{ name: 'order-detail', params: { id: order.id } }" >-->
                  <!--{{ order.id }}-->
                <!--</router-link>-->
                <!--</td>-->
              <!--<td>{{ order.total_price }}</td>-->
              <!--<td>{{ order.discount }}</td>-->
              <!--<td>{{ order.discount_price}}</td>-->
            <!--</tr>-->
            <!--</tbody>-->
          <!--</table>-->
        <!--</el-dialog>-->
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getLoadingInstance } from '../../helpers';

export default {
  name: 'CouponDetail',
  data() {
    return {
      ordersDialogVisible: false,
      currentOrders: null,
      coupons: [],
      coupon_info: null,
      tableConfigs: {
        columns: [
          'user_name',
          'order_id',
          'total_price',
          'discount',
          'discount_price',
          // 'get_num',
          // 'used_num',
          // 'created_at',
          'used_at',
          // 'detail',
        ],
        options: {
          headings: {
            user_name: '用戶名稱',
            order_id: '訂單ID',
            total_price: '總價格',
            discount_price: '優惠價',
            discount: '總優惠',
            // get_num: '已領取數量',
            // used_num: '已使用數量',
            // created_at: '使用時間',
            used_at: '使用時間',
            // detail: '訂單詳情',
          },
          filterable: ['user_name'],
          sortable: ['created_at', 'use_time'],
        },
      },
    };
  },
  computed: {
    ...mapState({
      areaGroups: state => state.areaGroup.areaGroups,
    }),
    ...mapGetters([
      'otherLanguages',
      'hasPermission',
    ]),
  },
  methods: {
    showOrder(orders) {
      this.currentOrders = orders;
      this.ordersDialogVisible = true;
    },
  },
  mounted() {
    const loading = getLoadingInstance();
    this.$api.coupon.getById(this.$route.params.id).then(({ data: response }) => {
      this.coupons = response.coupons;
      this.coupon_info = response.coupon_info;
    }).catch((e) => {
      this.$notify.error({
        title: e.response.data.message,
      });
      this.$router.push({ name: 'coupon' });
    }).finally(() => {
      loading.close();
    });
  },
  components: {},
};
</script>
<style lang="scss">
</style>
