<template>
  <ContentWrapper id="coupon">
    <div class="content-heading">
      優惠券列表
    </div>

    <div class="card card-default">
      <div class="card-body">
        <v-client-table id="coupon-table" :data="coupons" :columns="tableConfigs.columns"
                        :options="tableConfigs.options">

          <template slot="num" slot-scope="props">
            {{ props.row.num }}%
          </template>

          <template slot="discount" slot-scope="props">
            <span v-if="props.row.discount_percent">
              {{ props.row.discount_percent }}%
            </span>
            <span v-if="props.row.discount_price">
              {{ props.row.currency }} {{ props.row.discount_price }}
            </span>
          </template>

          <template slot="use_amount_condition" slot-scope="props">
            <span v-if="props.row.use_amount_condition">
              滿{{ props.row.currency }} {{ props.row.use_amount_condition }}
            </span>
            <span v-else>
              無門檻
            </span>
          </template>

          <template slot="new_user_only" slot-scope="props">
            {{ props.row.new_user_only ? '是' : '否' }}
          </template>

          <template slot="public" slot-scope="props">
            {{ props.row.public ? '是' : '否' }}
          </template>

          <template slot="time" slot-scope="props">

            <span v-if="props.row.validity_period && props.row.validity_period.length">
              {{ props.row.validity_period[0] }} - {{ props.row.validity_period[1]}}
            </span>
          </template>

          <template slot="options" slot-scope="props">
            <!--<button type="button" class="btn btn-primary copy-share-link"-->
                    <!--@click="copy"-->
                    <!--:data-clipboard-text="props.row.share_link"-->
            <!--&gt;-->
              <!--<el-tooltip placement="top" content="複製領取鏈接">-->
                <!--<i class="fa fa-clipboard" aria-hidden="true"></i>-->
              <!--</el-tooltip>-->
            <!--</button>-->
            <el-button type="primary" size="small"
                       @click="$router.push({ name: 'coupon-detail', params: { id: props.row.code }})">統計</el-button>
            <el-button type="primary" size="small"
                       @click="$router.push({ name: 'coupon-edit', params: { id: props.row.code }})">詳細</el-button>
            <el-button type="danger" size="small" @click="removeCoupon(props.row.code)">
              刪除優惠碼
            </el-button>
            <el-button type="danger" size="small" @click="downloadImg(props.row.code)">
              下載二維碼
            </el-button>
          </template>
        </v-client-table>

      </div>
    </div>

  </ContentWrapper>
</template>
<script>
import { mapState } from 'vuex';
import Clipboard from 'clipboard';
import appConfig from '@/app.config';

export default {
  name: 'Coupons',
  mounted() {
    this.$store.dispatch('fetchCoupons');
  },
  data() {
    return {
      tableConfigs: {
        columns: [
          // 'area_group_name',
          'name',
          'code',
          // 'coupon_type_str',
          'discount',
          'use_amount_condition',
          'time',
          'created_at',
          'new_user_only',
          'public',
          'options',
        ],
        options: {
          headings: {
            // area_group_name: '優惠券區域群組',
            name: '優惠券名稱',
            code: '優惠碼',
            // coupon_type_str: '優惠券類型',
            coed: '優惠券代碼',
            discount: '優惠',
            use_amount_condition: '門檻',
            time: '有效時間',
            created_at: '創建時間',
            new_user_only: '首單使用',
            public: '公開領取',
            options: '操作',
          },
          filterable: ['name', 'code'],
          sortable: ['created_at', 'count'],
        },
      },
    };
  },
  computed: {
    ...mapState({
      coupons: state => state.coupon.coupons,
    }),
  },
  methods: {
    downloadImg(code) {
      const { API_HOST } = appConfig;
      const url = `${API_HOST}/coupon-img/${code}`;
      window.open(url);
      // const content = `<div v-html="${image}" style="height: 100%"></div>`;
      // // const content = `<span style="color: red;">${order.issue.question_admin}</span>  ： ${order.issue.question}`;
      // this.$alert(content, '', {
      //   showConfirmButton: false,
      //   showClose: false,
      //   closeOnClickModal: true,
      //   dangerouslyUseHTMLString: true,
      // });
      // const loading = getLoadingInstance();
      // const { API_HOST } = appConfig;
      // // const formData = new FormData();
      // // _.forEach(this.checked, id => formData.append('ids[]', id));
      // const url = `${API_HOST}/coupon-img/code`;
      // // const url = 'https://wx.qlogo.cn/mmopen/vi_32/DYAIOgq83erUUf7gjj3W2PibpHvIBZxluhD6qJtZsxAcUdUSibQtuVNBicEUQiawhT0TIqIlR54rLAnoJffIibYLm4g/0';
      // const x = new XMLHttpRequest();
      // x.open('GET', url, true);
      // x.responseType = 'blob';
      // x.onload = function (e) {
      //   console.log(e);
      //   loading.close();
      //   download(e.target.response, '二維碼.png', e.target.response.type);
      // };
      // x.send();
      // this.$api.coupon.getImg(code).then(({ data: response }) => {
      //   // this.parcels = [...response.data];
      //   // console.log(response);
      //   const test = btoa(
      //     new Uint8Array(response).reduce((data, byte) => data + String.fromCharCode(byte), ''),
      //   );
      //   console.log(`data:image/png;base64,${test}`);
      //   // download(test, '二維碼.jpg', 'image/png');
      // }).catch(error => showErrorMessage(error)).finally(() => {
      //   // loading.close();
      // });
    },
    removeCoupon(id) {
      this.$confirm('你確定要刪除這個優惠券嗎？', '提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('removeCoupon', id).then(() => {
          this.$notify.success({
            title: '優惠券已刪除',
          });
        }).catch((e) => {
          this.$notify.error({
            message: e.response.data.message,
          });
        });
      });
    },
    copy() {
      const clipboard = new Clipboard('.copy-share-link');
      clipboard.on('success', () => {
        this.$notify.success({
          title: '複製成功',
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        // 不支持复制
        this.$notify.error({
          message: '該瀏覽器不支持自動複製，可進入詳情頁查看鏈接',
        });
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>
<style lang="scss">

</style>
