<template>
  <ContentWrapper id="coupon-save">
    <div class="content-heading">{{ title }}</div>
    <div class="card card-default">
      <div class="card-body">
        <el-form :model="couponForm" :rules="rules" ref="couponForm" label-width="200px" :disabled="edit">

          <el-form-item>
            <template slot="label">
              優惠券代碼
              <el-tooltip placement="top" content="不填寫則隨機生成">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </el-tooltip>
            </template>
            <el-input v-model.trim="couponForm.code"/>
          </el-form-item>

          <!--<el-form-item label="優惠券領取鏈接" v-if="couponForm.code">-->
            <!--<el-input v-model.trim="couponForm.share_link"/>-->
          <!--</el-form-item>-->

          <el-form-item label="優惠券名稱">
            <el-form-item prop="name" :rules="rules.name" label-width="0" class="is-required">
              <el-input placeholder="請輸入優惠券名稱" v-model.trim="couponForm.name" clearable/>
            </el-form-item>
          </el-form-item>

          <el-form-item label="優惠券限制地區（不選則無限制）">
            <el-select v-model="couponForm.district_ids" multiple filterable  style="width: 280px">
              <el-option
                  v-for="district in districts"
                  :key="district.id"
                  :value="district.id"
                  :label="district.name"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!--<el-form-item label="優惠類型" prop="coupon_type">-->
            <!--<el-select v-model="couponForm.coupon_type" placeholder="請選擇訂單類型">-->
              <!--<el-option :value='1' label="全單折扣"></el-option>-->
              <!--&lt;!&ndash;<el-option :value='2' label="商品折扣"></el-option>&ndash;&gt;-->
              <!--<el-option :value='3' label="運費折扣"></el-option>-->
              <!--<el-option :value='4' label="免運費"></el-option>-->
            <!--</el-select>-->
          <!--</el-form-item>-->

          <el-form-item label="優惠方式" prop="type" v-if="couponForm.coupon_type !== 4" class="is-required">

            <el-form-item label-width="0" prop="discount_percent" class="mb-4">
              <el-radio v-model="couponForm.discount_type" :label="1">
                折扣
              </el-radio>
              <el-input-number style="width: 180px" type="number"
                        :min="0"
                        :max="100"
                        v-model.number="couponForm.discount_percent"
                        :disabled="couponForm.discount_type !== 1"
                        :precision="0"
              >
                <template slot="append">%</template>
              </el-input-number>
            </el-form-item>
            <el-form-item llabel-width="0" prop="discount_price" class="mb-4">
                <el-radio v-model="couponForm.discount_type" :label="2">金額</el-radio>
                <el-input-number v-model.number="couponForm.discount_price" :precision="2"
                                 :min="0"
                                 :disabled="couponForm.discount_type !== 2"/>
            </el-form-item>

          </el-form-item>

          <el-form-item>
            <template slot="label">
              優惠碼使用總數
              <el-tooltip placement="top" content="如填寫0則代表不限制數量">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </el-tooltip>
            </template>
            <el-input-number v-model.number="couponForm.total" :precision="0"
                             :min="0"/>
          </el-form-item>

          <el-form-item>
            <template slot="label">
              用戶限制使用數量
              <el-tooltip placement="top" content="如填寫0則代表不限制使用">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </el-tooltip>
            </template>

            <el-input-number v-model.number="couponForm.limit" :precision="0"
                             :min="0"/>
          </el-form-item>

          <el-form-item>
            <template slot="label">
              訂單滿多少金額可用
              <el-tooltip placement="top" content="如填寫0則代表不限定金額">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </el-tooltip>
            </template>

            <el-input-number v-model.number="couponForm.use_amount_condition" :precision="2"
                             :min="0"/>
          </el-form-item>

          <el-form-item label="是否公開顯示">
            <el-switch v-model="couponForm.public"/>
          </el-form-item>

          <el-form-item label="是否僅限首單使用">
            <el-switch v-model="couponForm.new_user_only"/>
          </el-form-item>

          <el-form-item label="優惠券時間" prop="validity_period">
            <el-date-picker
                class="w80"
                v-model="couponForm.validity_period"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item v-if="!edit">
            <el-button type="primary" @click="save()">保存</el-button>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState } from 'vuex';
import { getLoadingInstance } from '../../helpers';

export default {
  name: 'CouponSave',
  data() {
    return {
      districts: [],
      nameLangTab: 'zh_HK',
      edit: false,
      title: '創建優惠券',
      couponForm: {},
      rules: {
        name: { required: true, message: '請輸入名稱', trigger: 'blur' },
        area_group_id: { required: true, message: '請選擇優惠券區域群組', trigger: 'change' },
        discount_percent: {
          validator: (rule, value, callback) => {
            if (this.couponForm.discount_type === 1 && value <= 0 && this.couponForm.coupon_type !== 4) {
              callback(new Error('請輸入折扣'));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        },
        discount_price: {
          validator: (rule, value, callback) => {
            if (this.couponForm.discount_type === 2 && value <= 0 && this.couponForm.coupon_type !== 4) {
              callback(new Error('請輸入優惠金額'));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        },
        validity_period:
          {
            required: true,
            message: '請選擇優惠券時間',
            type: 'array',
            trigger: 'blur',
          },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const start = new Date();
              const end = moment().endOf('day').toDate();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '一周',
            onClick(picker) {
              const start = new Date();
              const end = moment().add(7, 'days').toDate();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '一個月',
            onClick(picker) {
              const start = new Date();
              const end = moment().add(1, 'months').toDate();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '三個月',
            onClick(picker) {
              const start = new Date();
              const end = moment().add(3, 'months').toDate();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '半年',
            onClick(picker) {
              const start = new Date();
              const end = moment().add(6, 'months').toDate();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '一年',
            onClick(picker) {
              const start = new Date();
              const end = moment().add(1, 'years').toDate();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '本月',
            onClick(picker) {
              const start = new Date();
              const end = moment().endOf('month').toDate();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '本年',
            onClick(picker) {
              const start = new Date();
              const end = moment().endOf('year').toDate();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      coupons: state => state.coupon.coupons,
      areaGroups: state => state.areaGroup.areaGroups,
    }),
  },
  methods: {
    initCouponForm() {
      this.couponForm = {
        area_group_id: null,
        coupon_type: 1,
        code: '',
        limit: 0,
        total: 0,
        discount_type: 1,
        use_amount_condition: 0,
        discount_price: 0,
        discount_percent: 0,
        validity_period: [],
        district_ids: [],
        name: '',
        public: false,
        new_user_only: false,
      };
    },
    save() {
      if (this.$refs.couponForm.validate((valid) => {
        if (valid) {
          const loading = getLoadingInstance();
          this.$store.dispatch('saveCoupon', this.couponForm).then(() => {
            const title = this.$route.name === 'coupon-create' ? '創建優惠券成功' : '修改優惠券成功';
            this.$notify({
              title,
              type: 'success',
            });
            this.$router.push({ name: 'coupons' });
          }).catch((e) => {
            this.$notify.error({
              message: e.response.data.message,
            });
          }).finally(() => {
            loading.close();
          });
        }
      })) ;
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === 'coupon-create') {
          this.title = '創建優惠券';
          this.edit = false;
          this.initCouponForm();
        } else {
          const code = this.$route.params.id;
          const loading = getLoadingInstance();
          this.$store.dispatch('fetchCoupons', code).then(() => {
            this.$nextTick(() => {
              const coupon = _.find(this.coupons, b => b.code === code);
              this.couponForm = { ...coupon };
              this.edit = true;
              this.title = `優惠券詳情-${coupon.name}`;
            });
          }).finally(() => {
            loading.close();
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$api.district.root().then(({ data: response }) => {
      this.districts = response.data;
    });

    // if (this.$route.name === 'coupon-create') {
    //   this.title = '創建優惠券';
    // }
    //
    // if (this.$route.name === 'coupon-edit') {
    //   const code = this.$route.params.id;
    //   const loading = getLoadingInstance();
    //   this.$store.dispatch('fetchCoupons', code).then(() => {
    //     const coupon = _.find(this.coupons, b => b.code === code);
    //     this.couponForm = { ...coupon };
    //     this.edit = true;
    //     this.title = `優惠券詳情-${coupon.name}`;
    //   }).finally(() => {
    //     loading.close();
    //   });
    // }
  },
  components: {},
};
</script>
<style lang="scss">
</style>
